import { SvgIcon, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";

const PickleIcon: OverridableComponent<SvgIconTypeMap> = (props: unknown) => {
    return (
        <SvgIcon {...props}>
            <path d="M 59.004934 87.190048 C 52.796053 91.49966 42.002467 86.404552 38.507401 74.79195 C 33.59375 58.699898 33.59375 41.397758 38.507401 25.305707 C 42.002467 13.608186 52.796053 8.491848 59.004934 12.907609 C 65.296053 17.089844 66.796875 26.006284 64.699836 33.309273 C 61.389803 44.200068 61.389803 55.897588 64.699836 66.809613 C 66.796875 74.091372 65.296053 82.795516 59.004934 87.190048 Z M 59.004934 87.190048 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 43.009868 16.898777 C 42.393092 16.410496 41.509046 16.092052 40.707237 16.092052 C 38.610197 16.092052 36.903783 17.790421 36.903783 19.892154 C 36.903783 21.399457 37.808388 22.800611 39.103618 23.395041 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 35.999178 35.70822 C 35.690789 35.602072 35.402961 35.602072 35.094572 35.602072 C 32.997533 35.602072 31.291118 37.300442 31.291118 39.402174 C 31.291118 41.503906 32.997533 43.202276 35.094572 43.202276 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 37.890625 72.499151 C 36.307566 72.902514 35.094572 74.409817 35.094572 76.193105 C 35.094572 78.294837 36.800987 79.993207 38.898026 79.993207 C 39.494243 79.993207 40.008224 79.908288 40.501645 79.589844 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 54.009046 35.495924 C 53.104441 34.094769 51.192434 33.691406 49.794408 34.710428 C 48.396382 35.602072 48.005757 37.491508 48.992599 38.892663 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 54.790296 55.494226 C 56.003289 54.305367 55.900493 52.309783 54.708059 51.205842 C 53.495066 49.995754 51.500822 50.101902 50.390625 51.290761 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 52.796053 74.494735 C 53.495066 73.008662 52.796053 71.097996 51.192434 70.503567 C 49.691612 69.802989 47.800164 70.503567 47.203947 72.095788 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 65.707237 72.201936 C 67.393092 71.904721 68.708882 70.29127 68.708882 68.507982 C 68.708882 66.40625 67.002467 64.70788 64.905428 64.70788 C 64.699836 64.70788 64.391447 64.70788 64.206414 64.792799 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 62.808388 42.798913 C 64.905428 42.798913 66.509046 44.497283 66.509046 46.599015 C 66.509046 48.700747 64.802632 50.399117 62.705592 50.399117 C 62.602796 50.399117 62.397204 50.399117 62.294408 50.399117 " transform="matrix(0.19,0,0,0.184,0,0)" />
            <path d="M 65.707237 27.492357 C 67.393092 27.110224 68.708882 25.602921 68.708882 23.798404 C 68.708882 21.696671 67.002467 19.998302 64.905428 19.998302 C 64.802632 19.998302 64.699836 19.998302 64.597039 19.998302 " transform="matrix(0.19,0,0,0.184,0,0)" />
        </SvgIcon>
    );
}

export default PickleIcon;